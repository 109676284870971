//Class for handle users in app
import Permission from "./permission";


class User {


  constructor(username, userpassword, permission) {
    this.username = username;
    this.userpassword = userpassword;
    this.permission = permission;
  }
  setField(field, value) {
    this[field] = value;
  }
  getName() {
    return this.username;
  }

  getPassword() {
    return this.userpassword;
  }
  getPermission(){
    return this.permission;
  }
 
  getPermissionType(TypeMode, TypePerm){
    let comp = TypePerm << TypeMode;
    let res = 0;
    res = this.permission & comp; 
    if(res>0){
      return true;
    }else{
      return false;
    }
  }
  setAllPermission(TypeMode){
    let perm = Permission.READ | Permission.CREATE | Permission.MODIFY | Permission.DELETE; 
    perm = perm << TypeMode*4;
    this.permission = this.permission | perm;
  }

}

export default User;