import Tyre from "./tyre";
import Utils from "./utils";

class TyreDatabaseService {
  constructor() {
    this.baseurl = "https://app.talleresrasiga.es/backend/tyresoperations.php";
    this.sessionurl = "https://app.talleresrasiga.es/backend/checksession.php";
  }

  async getTyre(id) {
    try {
      const formData = new FormData();
      formData.append("operation", 2);
      formData.append("id", id);

      const response = await fetch(this.baseurl, {
        method: 'POST',
        mode: "cors",
        body: formData,
      });
     if (!response.ok) {
        throw new Error('Error al obtener los neumáticos');
      }
      const data = await response.json();
      const tyre = new Tyre ( data[0].ID, data[0].MARCA,data[0].MODELO,
        data[0].MEDIDA, data[0].STOCK, data[0].ICCV,data[0].TIPO, data[0].VISUAL)
      return tyre;
    } catch (error) {
      console.error('Error en la solicitud GET:', error);
      throw error;
    }
  }
  async getSearchTyres(search, value, order, nostock) {
    try {
      nostock = nostock ? 1 : 0;
      const formData = new FormData();
      formData.append("operation", 7);
      formData.append("search", search);
      formData.append("value", value);
      formData.append("order", order);
      formData.append("nostock", nostock);

      const response = await fetch(this.baseurl, {
        method: 'POST',
        mode: "cors",
        body: formData,
      });

      if (response.status === 204) {
        return null;
      }
     if (!response.ok) {
        throw new Error('Error al obtener los neumáticos');
      }
      const data = await response.json();
      const tyres = data.map(obj => new Tyre(obj.ID, obj.MARCA,
      obj.MODELO, obj.MEDIDA, obj.STOCK, obj.ICCV, obj.TIPO, obj.VISUAL));
      return tyres;
    } catch (error) {
      console.error('Error en la solicitud GET:', error);
      throw error;
    }
    
  }
  async getTyres(nostock) {
    try {
      nostock = nostock ? 1 : 0;
      const formData = new FormData();
      formData.append("operation", 3);
      formData.append("nostock", nostock);
      const response = await fetch(this.baseurl, {
        method: 'POST',
        mode: "cors",
        body: formData,
      });
     if (!response.ok) {
        throw new Error('Error al obtener los neumáticos');
      }
      const data = await response.json();
      const tyres = data.map(obj => new Tyre(obj.ID, obj.MARCA,
      obj.MODELO, obj.MEDIDA, obj.STOCK, obj.ICCV, obj.TIPO, obj.VISUAL));
      return tyres;
    } catch (error) {
      console.error('Error en la solicitud GET:', error);
      throw error;
    }
  }

  async addTyre(brand, model, size, stock, iccv, type, visual) {
    try {
      const formData = new FormData();
      formData.append("operation", 1);
      formData.append("brand", brand);
      formData.append("model", model);
      formData.append("size", size);
      formData.append("stock", stock);
      formData.append("iccv", iccv);
      formData.append("type", type);
      formData.append("visual", visual);

      const response = await fetch(this.baseurl, {
        method: 'POST',
        body: formData,
      });
      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error en la solicitud POST:', error);
      throw error;
    }
  }

  async editTyre(id, brand, model, size, stock, iccv, type, visual) {
    try {
      const formData = new FormData();
      formData.append("operation", 4);
      formData.append("id", id);
      formData.append("brand", brand);
      formData.append("model", model);
      formData.append("size", size);
      formData.append("stock", stock);
      formData.append("iccv", iccv);
      formData.append("type", type);
      formData.append("visual", visual);

      const response = await fetch(this.baseurl, {
        method: 'POST',
        body: formData,
      });
      if (response.status === 201) {
        
        Utils.addToast("Neumático editado","success");
        return true;
      } else {
        Utils.addToast("Neumático no editado","danger");

        return false;
      }
    } catch (error) {
      console.error('Error en la solicitud POST:', error);
      throw error;
    }
  }
  async deleteTyre(id) {
    try{
      const formData = new FormData();
      formData.append("operation", 5);
      formData.append("id", id);
      const response = await fetch(this.baseurl, {
        method: 'POST',
        body: formData,
      });
      if (response.status === 201) {
        Utils.addToast("Neumático eliminado","success");

        return true;
      } else {
        Utils.addToast("Neumático no eliminado","danger");

        return false;
      }

    }catch(error){
      console.error('Error en la solicitud POST:', error);
    }

  }
  async updateStockTyre(id, units){
    try{
      const formData = new FormData();
      formData.append("operation", 6);
      formData.append("id", id);
      formData.append("units", units);

      const response = await fetch(this.baseurl, {
        method: 'POST',
        body: formData,
      });
      if (response.status === 201) {
        Utils.addToast("Stock cambiado","success");

        return true;
      } else {
        Utils.addToast("Stock no cambiado","danger");

        return false;
      }

    }catch(error){
      console.error('Error en la solicitud POST:', error);
    }

  }
  async getLog() {
    try {
      const formData = new FormData();
      formData.append("operation", 8);
      const response = await fetch(this.baseurl, {
        method: 'POST',
        mode: "cors",
        body: formData,
      });
     if (!response.ok) {
        throw new Error('Error al obtener los neumáticos');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error en la solicitud GET:', error);
      throw error;
    }
  }
  async getLogSearch(search, value, valuesec) {
    try {
      //Check if date and add +1 day
      if(search==="DATE"){
        let fecha = new Date(valuesec);
        fecha.setDate(fecha.getDate() + 1); 
        valuesec = Utils.formatDate(fecha);
      }

      const formData = new FormData();
      formData.append("operation", 9);
      formData.append("search", search);
      formData.append("value", value);
      formData.append("valuesec", valuesec);

      const response = await fetch(this.baseurl, {
        method: 'POST',
        mode: "cors",
        body: formData,
      });
     if (!response.ok) {
        throw new Error('Error al obtener los neumáticos');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error en la solicitud GET:', error);
      throw error;
    }
  }

  async checkSession(){
  
    try {
      const response = await fetch(this.sessionurl, { method: "POST" });
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      alert("Error de red: " + error.message);
      return false;
    }
  }

} export default TyreDatabaseService