import { useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import TyreDatabaseService from '../class/tyredatabaseservice';


function CheckSession({setCanRender}) {

  const navigate = useNavigate();
  useEffect(() => {
    const dbtyre = new TyreDatabaseService();
    async function checkSessionUse(){
      const res = await dbtyre.checkSession();
      
      if(!res){
        navigate("/");
      }else{
        setCanRender(true);
      }
    }

    checkSessionUse();

  }, [setCanRender, navigate]);




  return null;
}

export default CheckSession