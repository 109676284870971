class Utils {
  static toasts = [];
  static listeners = [];

  static addToast(message, type) {
    this.toasts.push({ id: Date.now(), message, type, show: true });
    this.notifyListeners();
  }

  static deleteToast(toast) {
    this.toasts = this.toasts.filter(item => item !== toast);
    this.notifyListeners();
  }

  static subscribe(listener) {
    this.listeners.push(listener);
  }

  static unsubscribe(listener) {
    this.listeners = this.listeners.filter(l => l !== listener);
  }

  static notifyListeners() {
    this.listeners.forEach(listener => listener(this.toasts));
  }

  static formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
}

export default Utils;