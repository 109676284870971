import React, { useState } from 'react'
import RasigaNavBar from '../components/RasigaNavBar'
import { Container, Row, Form, Col, Button, ListGroup, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import TyreDatabaseService from '../class/tyredatabaseservice';
import Utils from '../class/utils';
import ScrollButton from '../components/ScrollButton';
import PaginationComponent from '../components/PaginationComponent';
import CheckSession from '../components/CheckSession';

const dbtyre = new TyreDatabaseService();

function LogView() {
  const [searchvalue, setSearchValue] = useState("");
  const [searchvaluesecond, setSearchValueSecond] = useState("");
  const [data, setData] = useState([]);
  const [inputtype, setInputType] = useState("SIZE");
  const [searching, setSearching] = useState(false);
  const [canrender, setCanRender] = useState(false);

  const fetchLogSearch = async () => {
    if (searchvalue === "") {
      Utils.addToast("Rellena los campos vacíos", "warning");
      return;
    }
    if ((searchvalue === "" || searchvaluesecond === "") && inputtype === "DATE") {
      Utils.addToast("Rellena los campos vacíos", "warning");
      return;
    }
    try {
      setSearching(true);
      const logdata = await dbtyre.getLogSearch(inputtype, searchvalue, searchvaluesecond);
      let arrayview = [];
      logdata.map((log) => {
        return arrayview.push(
          <ListGroup.Item
            key={log.ID}
            as="li"
            className=""
          >
            <div><b>Medida:</b> {log.SIZE}
              <br /><b>Operacion:</b> {log.OPERATION}
              <br /><b>Fecha:</b> {log.DATE}
              <br /><b>Usuario:</b> {log.USERNAME}</div>
          </ListGroup.Item>
        );
      });
      setData(arrayview);
      setSearching(false);
    } catch (error) {
      console.error('Error fetching tyres:', error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); 
    fetchLogSearch();


  };

  return (
    <>
      <CheckSession setCanRender={setCanRender} />
      {
        canrender ? (
          <>
            <ScrollButton />
            <RasigaNavBar />
            <Container>
              <Form onSubmit={handleSubmit} style={{ marginTop: "15px" }}>
                <Form.Group>
                  <Row className='mb-2'>
                    <Col className='mb-2' xs={12} lg={4}>
                      <Form.Label>Buscar por:</Form.Label>
                      <Form.Select value={inputtype}
                        onChange={(event) => {
                          setInputType(event.target.value)
                          setSearchValue("");
                          setSearchValueSecond("");
                        }}
                        aria-label='select search type'>
                        <option value="SIZE">Medida</option>
                        <option value="USERNAME">Usuario</option>
                        <option value="DATE">Fecha</option>
                      </Form.Select>
                    </Col>

                    {inputtype === 'SIZE' && (
                      <Col className='mb-2' xs={12} lg={6}>
                        <Form.Label>Medida: </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Búsqueda por medida"
                          name="search"
                          value={searchvalue}
                          onChange={(event) => { setSearchValue(event.target.value); }} />
                      </Col>
                    )}
                    {inputtype === 'USERNAME' && (
                      <Col className='mb-2' xs={12} lg={6}>
                        <Form.Label>Usuario:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Búsqueda por usuario"
                          name="search"
                          value={searchvalue}
                          onChange={(event) => { setSearchValue(event.target.value); }} />
                      </Col>
                    )}
                    {inputtype === 'DATE' && (
                      <>
                        <Col className='mb-2' xs={12} lg={2}>
                          <Form.Label>Fecha de inicio</Form.Label>
                          <Form.Control
                            type="date"
                            max={searchvaluesecond}
                            value={searchvalue}
                            onChange={(event) => { setSearchValue(event.target.value); }} />
                        </Col>
                        <Col className='mb-2' xs={12} lg={2}>
                          <Form.Label>Fecha de fin</Form.Label>
                          <Form.Control
                            disabled={searchvalue==="" ? true : false}
                            type="date"
                            min={searchvalue}
                            value={searchvaluesecond}
                            onChange={(event) => { setSearchValueSecond(event.target.value); }} />
                        </Col>

                      </>
                    )}

                    <Col xs={12} lg={2} className='mb-2 d-flex align-items-center flex-row-reverse'>
                          <Button onClick={fetchLogSearch} size='lg'><FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon></Button>
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
              <ListGroup as="ol">
                {
                  searching ? (
                    <div>Buscando...<Spinner animation="border" variant="primary" /></div>
                  ) : (
                    <PaginationComponent itemsPerPage={50} items={data} paginatorviewpages={4} />
                  )


                }
              </ListGroup>

            </Container>
          </>
        ) : (null)
      }


    </>
  )
}

export default LogView