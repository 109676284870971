import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import User from "../class/user";
import FormUser from "./FormUser";
import Utils from "../class/utils";

//View to create users
//TODO CHECK PERMISSIONS COULD CREATE USERS

function CreateUser({ recieveShow, gshow }) {
  const [show, setShow] = useState(false);
  const [user, setUser] = useState(new User("", "", 0));
  const [cbxpermissions, setCBXPermissions] = useState([
    { id: 1,  isChecked: false }, //usuarios
    { id: 2, isChecked: false },  //tyres
  ]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const reciveUser = (name, pass) => {
    let newuser = new User("", "", 0);
    newuser.username = name;
    newuser.userpassword = pass;
    setUser(newuser);
  };

  const handleCheckboxChange = (id) => {
    setCBXPermissions(prevPermissions => {
      return prevPermissions.map(permission => {
        if (permission.id === id) {
          return { ...permission, isChecked: !permission.isChecked };
        }
        return permission;
      });
    });
  };

  function closeModal() {
    handleClose();
    recieveShow(false);
  }


  function createUser() {

    //check spaces
    if(user.username.includes(" ")||
    user.userpassword.includes(" ")){
      Utils.addToast("El nombre o contraseña de usuario no puede contener espacios","warning");
      return;
    }

    //handle permisions
    for (let i = 0; i < cbxpermissions.length; i++) {
      if(cbxpermissions[i].isChecked){
        user.setAllPermission(i);
      }
      
    }

    //insert bd
    const url = "https://app.talleresrasiga.es/backend/useroperations.php";
    const formData = new FormData();
    formData.append("username", user.username);
    formData.append("userpassword", user.userpassword);
    formData.append("permissions", user.permission);
    formData.append("operation", 2);



    const requestOptions = {
      method: "POST",
      body: formData,
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (response.status === 201) {
          Utils.addToast("Usuario añadido","success");
          closeModal();
        } else {
          Utils.addToast("Usuario no añadido","danger");

        }
      })

      .catch((error) => {
        Utils.addToast("Error de red","danger");

      });
  }

  useEffect(() => {
    if (gshow) {
      handleShow();
    }
  }, [gshow]);

  return (
    <>
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Creación de usuarios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormUser onChange={reciveUser}></FormUser>
            <Form.Group className="mb-3" controlId="formCreateUser">
              <Form.Label>Permisos de usuario:</Form.Label>
              <div key="inline-checkbox" className="mb-3">
                <Form.Check
                  inline
                  label="Usuarios"
                  name="group1"
                  type="checkbox"
                  id="inline-checkbox-1"
                  checked={cbxpermissions[0].isChecked}
                  onChange={() => handleCheckboxChange(cbxpermissions[0].id)}
                />
                <Form.Check
                  inline
                  label="Neumáticos"
                  name="group1"
                  type="checkbox"
                  id="inline-checkbox-2"
                  checked={cbxpermissions[1].isChecked}
                  onChange={() => handleCheckboxChange(cbxpermissions[1].id)}


                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={createUser}>
            Subir usuario
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default CreateUser;
