import React, { useCallback, useState, useEffect} from 'react';
import { Button, Col, Container, Form, Row} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import TyreDatabaseService from '../class/tyredatabaseservice';
import RasigaNavBar from '../components/RasigaNavBar';
import RowTyre from '../components/RowTyre';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faBroom, faPlus } from '@fortawesome/free-solid-svg-icons';
import Utils from '../class/utils';
import ScrollButton from '../components/ScrollButton';
import CheckSession from '../components/CheckSession';

const dbtyre = new TyreDatabaseService();

function TyresView() {
  const [data, setData] = useState([]);
  const [searchby, setSearchBy] = useState('MEDIDA');
  const [searchvalue, setSearchValue] = useState('');
  const [orderby, setOrderBy] = useState('MARCA');
  const [shownostock, setShowNoStock] = useState(true);
  const [isloading, setIsLoading] = useState(false);
  const [firstSearch, setFirstSearch] = useState(false);
  const [canrender, setCanRender] = useState(false);
  const [searchformtype, setSearchFormType] = useState("number");

  const navigate = useNavigate();

  useEffect(()=>{
    switch (searchby) {
      case "MEDIDA":
        setSearchFormType("number");
        break;
      default:
        setSearchFormType("text");
        break;
    }
  },[searchby]);

  

  const editTyre = useCallback((id) =>{
    navigate("/CMTyreView",  { state: { id: id} });
  }, [navigate]);


  const delTyre = useCallback((id) =>{
    let res = false;
    if(id){
      res = dbtyre.deleteTyre(id);
      if(res){
        //setFirstSearch(false);
        const updata = data.filter(tyre => tyre.id !== id);
        setData(updata);
      }
    }
  }, [data]);

  const changeStock = useCallback((tyre, operation) =>{
    let number = null;
    if(operation > 0){
      number = prompt('Unidades a añadir: ');
    }else{
      number = prompt('Unidades a retirar: ');
    }
    if(number){
      
      const units = parseInt(tyre.stock) +  operation * parseInt(number);
      if(units>=0){
        dbtyre.updateStockTyre(tyre.id, units);
        tyre.stock=units;
        let tyresarray = [];
        tyresarray.push(tyre);
        setData(tyresarray);
      }else{
      
        Utils.addToast("ERROR: Retirados mas unidades que en stock","danger");
      }
    }
  }, []);


  
  const searchBy = async () => {
    
    if (searchvalue.length > 0) {
      try {
        setIsLoading(true);
        setFirstSearch(true);
        const tyresdata = await dbtyre.getSearchTyres(searchby, searchvalue, orderby, shownostock);
        setData(tyresdata);
        setIsLoading(false);
      } catch (error) {
        console.error('Error searching tyres:', error);
      }
    }else{
      Utils.addToast("Introduzca valor de busqueda","warning");

    }
  };

  


  const handleSubmit = (event) => {
    event.preventDefault(); 
    searchBy();


  };

  return (
    <div>
      <CheckSession setCanRender={setCanRender}/> 

      {
        canrender ? (  
        <>
        <RasigaNavBar/>
        <ScrollButton/>
        <Container>
          <Row>
            <Form onSubmit={handleSubmit}>
              <Form.Group >
                <Row className="mb-2">
                  <Col xs={6}>
                    <Form.Label className='form-label-resp'>Buscar por:</Form.Label>
                    <Form.Select className='form-control-resp' aria-label="select search by" 
                    value={searchby}
                    onChange={(event) => {setSearchBy(event.target.value);}}>
                      <option value="MEDIDA">Medida</option>
                      <option value="MARCA">Marca</option>
                      <option value="MODELO">Modelo</option>
                    </Form.Select>
                  </Col>
                  <Col xs={6}>
                    <Form.Label className='form-label-resp'>Ordenar por:</Form.Label>
                    <Form.Select className='form-control-resp' aria-label="select search by"
                    value={orderby}
                    onChange={(event) => {setOrderBy(event.target.value);}}>
                      <option value="MARCA">Alfabetico</option>
                      <option value="STOCK DESC">Stock</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="mb-2 d-flex justify-content-end">
                  <Col className="mb-1 " xs={12} md={9} lg={10}>
                    
                      <Form.Control
                        className='form-control-resp'
                        type={searchformtype}
                        placeholder="Búsqueda"
                        name="search"
                        value={searchvalue}
                        onChange={(event) => {setSearchValue(event.target.value);}}
                      />
                    </Col>
                    <Col  xs={4} md={3} lg={2} className="d-flex justify-content-end">
                      <Col className='mb-1 me-1 '>
                        <Button  className='form-control-resp' onClick={() =>searchBy()}><FontAwesomeIcon icon={faMagnifyingGlass} /></Button>
                      </Col>
                      <Col className='mb-1 me-1 '>
                        <Button className='form-control-resp' variant="danger" onClick={() => {
                          setSearchValue('');
                          setFirstSearch(false);
                        }
                      } ><FontAwesomeIcon icon={faBroom} /></Button>
                      </Col>
                      <Col className='mb-1 me-1 '>
                        <Link to="/CMTyreView">
                          <Button  className='form-control-resp' variant="success">
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                        </Link>
                      </Col>
                    
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={12}>
                    <Form.Check 
                     
                      className='d-flex justify-content-start form-control-resp'
                      type="switch"
                      id="custom-switch"
                      label=" Mostrar neumáticos sin stock"
                      checked={shownostock}
                      onChange={() => {
                        setShowNoStock(!shownostock);
                      }}

                    />
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Row>
          {
            !firstSearch ? (
              <Row xs={6} className='form-label-resp justify-content-center resp-subtitle '>
                <Col xs={8}>Por favor, introduzca valor de búsqueda</Col>
              </Row>
            ):(
              isloading ?(
                <div>Cargando...</div>
              ):(
                <>
                  {
                    data.length>0 ? (
                      data.map((tyre) => (
                      <RowTyre key={tyre.id} tyre={tyre} changeStockAdd = {changeStock}
                      changeStockMin = {changeStock} editTyre = {editTyre}
                      delTyre = {delTyre} enableactions ={true}
                        />
                      )
                      )
          
                    ) : (<div>No hay resultados</div>)
                  }
                </>
              )
            )
          
          }

        </Container>
        </>
        ):(null)
      }
    </div>
    


  );
}

export default TyresView;