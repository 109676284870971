import React, { useState, useEffect } from 'react'
import { Button, Col, Container, Image, Row, Form } from 'react-bootstrap'
import User from '../class/user';
import FormUser from '../components/FormUser';
import { useNavigate } from 'react-router-dom';
import Utils from '../class/utils';

function LoginView() {
  const [user, setUser] = useState(new User('','',0));
  const [remind, setRemind] = useState(false);
  const navigate = useNavigate();

  

  useEffect(() => {
    const name = localStorage.getItem('authName');
    const pass = localStorage.getItem('authPassword');
    
    if (name&&pass) {
      let newuser = new User('','',0);
      newuser.username = name;
      newuser.userpassword = pass;
      setUser(newuser);
      setRemind(true);
    }
  }, []);

  const reciveUser = (name, pass) =>{
    let newuser = new User('','',0);
    newuser.username = name;
    newuser.userpassword = pass;
    setUser(newuser);
  }

  function handleCheckChange(event){
    setRemind(event.target.checked);
  }

  function checkReminder(){
    if(remind){
      localStorage.setItem("authName", user.username);
      localStorage.setItem("authPassword", user.userpassword);
      
    }else{
      localStorage.removeItem('authName');
      localStorage.removeItem('authPassword');
    }

  }


  function login(){
    const url = 'https://app.talleresrasiga.es/backend/useroperations.php';
    const formData = new FormData();

    formData.append('username', user.username);
    formData.append('userpassword', user.userpassword);
    formData.append('operation', 1);


    const requestOptions = {
      method: 'POST',
      body: formData
    };


    fetch(url, requestOptions)
      .then(response =>{
        if(response.status === 200){
          checkReminder();
          navigate("TyresView");
        }else{
          Utils.addToast("Usario no encontrado","danger");

        }
      })

      .catch(error => {
        Utils.addToast("Error de red","danger");


      });
  }

  return (
      <Container>
        <Row  className='mb-2 d-flex justify-content-center align-items-center'>
          <Image className='img-logo' src='rasigacore.png'></Image>
        </Row>
        <Row className='mb-2 d-flex justify-content-center align-items-center'>
          <Col xs={8} lg={6}>
            <FormUser onChange={reciveUser} username={user.username} userpass={user.userpassword} remind={remind}></FormUser>
            <div key="inline-checkbox" className="mb-3">
                <Form.Check
                  inline
                  label="Recordar usuario"
                  name="group1"
                  type="checkbox"
                  id="inline-checkbox-1"
                  checked={remind}
                  onChange={handleCheckChange}
                 
                />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button  onClick={login}>Entrar</Button>
          </Col>
        </Row>
      </Container>
  )
}

export default LoginView