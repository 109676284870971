import React, { useState } from 'react'
import RasigaNavBar from '../components/RasigaNavBar'
import CSVReader from '../components/CSVReader'
import { Container, Button } from 'react-bootstrap'
import CreateUser from '../components/CreateUser'
import CheckSession from '../components/CheckSession'
import Utils from '../class/utils'

function OptionsView() {
  const [activemodal, setActiveModal] = useState(false);
  const [canrender, setCanRender] = useState(false);

   function  checkSuperUser(pass){

      const url = 'https://app.talleresrasiga.es/backend/useroperations.php';
      const formData = new FormData();
  
      formData.append('pass', pass);
      formData.append('operation', 4);

      
    const requestOptions = {
      method: 'POST',
      body: formData
    };


    fetch(url, requestOptions)
      .then(response =>{
        if(response.status === 200){
          setActiveModal(true);
        }else{
          Utils.addToast("Contraseña erronea", "danger");
        }
      })

      .catch(error => {
        Utils.addToast("Error de red","danger");


      });
  
  




  }

  function showModal(){
    let pass = prompt("Contraseña super usuario:");
    checkSuperUser(pass);

  }
  const recieveShow = (show) => {
    setActiveModal(show);
  }
  return (
    <>
    <CheckSession setCanRender={setCanRender}/> 
    {
      canrender? (
       <>
      <RasigaNavBar/>
      <Container>
        <CSVReader/>
        <Button onClick={showModal} size='lg'>Crear Usuario</Button>
        {activemodal ? <CreateUser recieveShow={recieveShow} gshow={activemodal}/> : null}
      </Container>
       </> 

      ):(null)
    }
    <div>
    </div>

    </>

  )
}

export default OptionsView