import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Utils from '../class/utils';
function RasigaNavBar() {

  const navigate = useNavigate();

  function logout(){
    const url = 'https://app.talleresrasiga.es/backend/useroperations.php';
    const formData = new FormData();

    formData.append('operation', 3);


    const requestOptions = {
      method: 'POST',
      body: formData
    };

    fetch(url, requestOptions)
      .then(response =>{
        if(response.status === 200){
          navigate("/");
          
        }else{
          Utils.addToast("Error cerrando sesion","danger");

        }
      })

      .catch(error => {
        Utils.addToast("Error de red","danger");


      });
  }

  return (
    <Navbar variant="dark" bg="secondary" expand="xxl">
      <Container>
        <Navbar.Brand >RasigaCore</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/TyresView">Neumáticos</Nav.Link>
            <Nav.Link  as={Link} to="/OptionsView">Opciones</Nav.Link>
            <Nav.Link  as={Link} to="/LogView">Log</Nav.Link>
            <Nav.Link  onClick={logout}>Cerrar Sesión</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )

}

export default RasigaNavBar